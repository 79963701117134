<template>
    <div class="container">
        <div class="column">
            <div class="columns">
                <div v-show="!isMobile" class="columns  pt-3">
                    <div class="column  mr-0 pr-0">
                           <button class="button is-static has-text-black" >Mostrar</button>
                    </div>
                    <div class="column  pl-0 control" v-tooltip="'Filas'">
                        <div class="select ">
                            <select class="options" v-model="mostrar_cantidad">

                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="500">500</option>
                                <option value="1000">1000</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div v-show="isMobile" class="columns has-text-right is-mobile pt-3">
                    <div class="column  mr-0 pr-0 has-text-right">
                           <button class="button is-static has-text-black">Mostrar</button>
                    </div>
                    <div class="column  has-text-left control">
                        <div class="select ">
                            <select class="options" v-model="mostrar_cantidad">
                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="500">500</option>
                                <option value="1000">1000</option>
                            </select>
                        </div>
                    </div>
                </div>
                <!-- <div v-show="!isMobile"  class="column is-flex-grow-0 has-text-center is-4"> -->
                    <!-- Buscador -->
                    <!-- <div class=" field ">
                        <p class="control has-icons-left ">
                            <input class="input " type="text" placeholder="Buscar impresiones" v-model="buscar">
                            <span class="icon is-left">
                                <i class="fas fa-search" ></i>
                            </span>
                        </p>
                    </div>
                </div> -->
                <div v-show="!isMobile" class="column has-text-left  ">
                    <div class="buttons ">
                        <button class="button has-background-info has-text-white" @click="filtro">Filtro</button>
                    </div>
                </div>
                <div v-show="isMobile"  class="columns is-mobile has-text-center ml-3">
                    <!-- Buscador -->
                    <div class="column is-4">
                        <!-- <div class=" field">
                            <p class="control has-icons-left ">
                                <input class="input " type="text" placeholder="Buscar impresion" v-model="buscar">
                                <span class="icon is-left">
                                    <i class="fas fa-search" ></i>
                                </span>
                            </p>
                        </div> -->
                    </div>
                    <div class="column has-text-center pl-1">
                        <div class="buttons">
                            <button class="button has-background-info has-text-white" @click="filtro">Filtro</button>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="modal" :class="{'is-active': filtrar}">
                        <div id="modal-background" class="modal-background " style="background-color: rgb(197, 197, 197, 0.1)"></div>
                        <div class="modal-content">
                            <div class="container has-text-centered has-background-white p-3 px-5" id="modal">

                                <div class="columns">
                                    <div class="column is-9 pb-0" :class="{'ml-5':!isMobile}">
                                        <h1 class="is-size-3 has-text-black has-text-weight-bold pt-3">Filtrar tabla</h1>
                                    </div>
                                    <div class="column" :class="{'mt-3 p-0 pt-2':!isMobile}">
                                        <button class="button verde-puwic mb-0 has-text-white has-text-weight-bold" @click="limpiarFiltro">Limpiar</button>
                                    </div>
                                </div>

                                <div :class="{'modal-scrolleable' : isMobile}">

                                    <!-- Anuncio -->
                                    <div class="field columns" :class="{'mb-0 pt-1' : isMobile, '': !isMobile}">
                                        <div class="column is-one-fifth ml-4 pt-2">
                                            <label class="label" :class="{'has-text-right': !isMobile}" >Nombre del anuncio</label>
                                        </div>
                                        <div class="columns has-text-centered ml-0 mr-0 w-100 is-align-self-center">
                                            <div class="control column is-flex-grow-0" :class="{'py-1' : isMobile}">
                                                <div class="select" >
                                                    <select class="options" v-model="tipos_filtro.anuncio">
                                                        <option value="contiene">contiene</option>
                                                        <option value="no contiene">no contiene</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="control column" :class="{'pt-1' : isMobile}">
                                                <input class="input" v-model="datos_filtro.anuncio" name="anuncio" type="text">
                                            </div>

                                        </div>
                                    </div>

                                    <!-- Anunciante -->
                                    <div class="field columns" :class="{'mb-0' : isMobile}">
                                        <div class="column is-one-fifth ml-4 mt-2 pt-0" style="min-width: 50px">
                                            <label class="label" :class="{'has-text-right': !isMobile}">Nombre del anunciante</label>
                                        </div>
                                        <div class="columns has-text-centered ml-0 mr-0 w-100 is-align-self-center">
                                            <div class="control column is-flex-grow-0" :class="{'py-1': isMobile}">
                                                <div class="select" style="width: 139px">
                                                    <select class="options" v-model="tipos_filtro.anunciante" style="width: 139px">
                                                        <option value="contiene">contiene</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="control column" :class="{'pt-1': isMobile}">
                                                <input class="input" v-model="datos_filtro.anunciante" name="anunciante" type="text">
                                            </div>

                                        </div>
                                    </div>


                                    <!-- Punto wifi -->
                                    <div class="field columns mb-0" :class="{'pt-1' : isMobile, '': !isMobile}">
                                        <div class="column is-one-fifth ml-4 pt-2">
                                            <label class="label" :class="{'has-text-right': !isMobile}" >Nombre del punto wifi</label>
                                        </div>
                                        <div class="columns has-text-centered ml-0 mr-0 w-100 is-align-self-center">
                                            <div class="control column is-flex-grow-0" :class="{'py-1' : isMobile}">
                                                <div class="select" >
                                                    <select class="options" v-model="tipos_filtro.punto_wifi">
                                                        <option value="contiene">contiene</option>
                                                        <option value="no contiene">no contiene</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="control column" :class="{'pt-1' : isMobile}">
                                                <input class="input" v-model="datos_filtro.punto_wifi" name="punto_wifi" type="text">
                                            </div>

                                        </div>
                                    </div>

                                    <!-- Fecha Desde y Hasta-->
                                    <div class="field columns mt-0 mb-0" :class="{'mb-0 mt-2': isMobile}">
                                        <div class="column is-one-fifth ml-4 pt-2 is-align-self-center" style="min-width: 50px">
                                            <label class="label" :class="{'has-text-right': !isMobile}">Fecha y hora impresion</label>
                                        </div>
                                        <div class="column">
                                            <div class="columns">
                                                <div class="column is-flex-grow-0" >
                                                    <label class="label mt-2" style="width: 139px; margin: auto">Desde</label>
                                                </div>
                                                <div class="control column ">

                                                    <input type="date" class="input input2" v-model="datos_filtro.desde" />

                                                </div>
                                            </div>

                                            <div class="columns">
                                                <div class="column is-flex-grow-0 " >
                                                    <label class="label mt-2" style="width: 139px; margin: auto">Hasta</label>
                                                </div>

                                                <div class="control column ">

                                                    <input type="date" class="input input2" v-model="datos_filtro.hasta" />

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Botones -->
                                    <div class="columns">
                                        <div class="column">
                                            <div class="buttons">
                                                <div class="column is-4 px-1">
                                                    <button class="button has-background-danger is-outlined btn has-text-white has-text-weight-bold" style="width: 100%" @click="filtrar = false">Cancelar</button>
                                                </div>
                                                <div class="column pl-0">
                                                    <button class="button verde-puwic is-outlined btn has-text-white has-text-weight-bold" :class="{'ml-2':!isMobile}" style="width: 100%" @click="filtro_avanzado">Filtro</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <button class="total-element">Total: {{total}}</button>
        <button v-show="filtrado" class="total-element mx-0 pointer" @click="filtro">Total filtrado: {{info.total}}</button>

        <div class="column table-container">
            <table class="table  has-background-white-ter" style="width: 100%">
                <thead>
                    <tr>
                    <th>ID</th>
                    <th>Nombre del anuncio</th>
                    <th>Nombre del anunciante</th>
                    <th>Nombre del punto wifi</th>
                    <th>Fecha y hora impresion</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(impresion,index) in impresiones" :index="index" :key="impresion.id" :class="{'fondo-fila':index%2==0 && !impresion.tildado,'seleccionado': impresion.tildado, 'deseleccionado': !impresion.tildado}">
                        <td>{{impresion.id}}</td>
                        <td>{{impresion.anuncio}}</td>
                        <td>{{impresion.anunciante}}</td>
                        <td>{{impresion.punto_wifi}}</td>
                        <td>{{impresion.fyh_impresion[0].split('-').reverse().join().replace(/[,]/gi,'-')}} {{impresion.fyh_impresion[1]}}</td>

                    </tr>
                    <tr>
                    </tr>
                </tbody>
            </table>

            <div v-show="preload" class="loader-wrapper is-active-loading">
                <div class="loader is-loading "></div>
            </div>

            <div v-if="!preload && impresiones.length == 0">
                <h1 class="has-text-centered is-size-4 has-text-grey">No hay impresiones</h1>
            </div>
        </div>

        <nav v-show="info.total > mostrar_cantidad" class="pagination is-right" role="navigation" aria-label="pagination">
            <router-link v-if="info.page > 1" class="pagination-previous" :to="prevComp">Anterior</router-link>
            <router-link v-else class="pagination-previous" disabled to="#">Anterior</router-link>
            <router-link v-if="info.page < info.pages" class="pagination-next" :to="nextComp">Siguiente</router-link>
            <router-link v-else class="pagination-next" disabled to="#">Next</router-link>
            <ul class="pagination-list" style="justify-content: center">
                <li v-show="info.prev > 1"><router-link class="pagination-link" :to="primero">1</router-link></li>
                <li v-show="info.prev > 2"><span class="pagination-ellipsis">&hellip;</span></li>
                <li v-show="info.prev != null"><router-link class="pagination-link" :to="prevComp">{{info.prev}}</router-link></li>
                <li><router-link class="pagination-link is-current" to="#">{{info.page}}</router-link></li>
                <li v-show="info.next != null"><router-link class="pagination-link" :to="nextComp">{{info.next}}</router-link></li>
                <li v-show="info.next < info.pages - 1 && info.next != null"><span class="pagination-ellipsis">&hellip;</span></li>
                <li v-show="info.next != null && (info.next < info.pages)"><router-link class="pagination-link" :to="ultimo">{{info.pages}}</router-link></li>
            </ul>
        </nav>

    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import store from '@/store'
import { GraphQLClient } from 'graphql-request'
import { computed, inject, onMounted, watch, watchEffect } from '@vue/runtime-core'
import { useRoute, useRouter } from 'vue-router'


export default {

    created () {
        this.consulta_filtro()
        this.impresiones_aux = this.impresiones
    },

    setup(){
        const impresiones = ref([])
        const impresiones_aux = ref([])
        const mostrar_cantidad = ref(100)
        const todo = ref(false)
        const route = useRoute()
        const router = useRouter()
        const buscar = ref('')
        const info = ref({count: 0, pages:0, next:0, prev: 0, page: 0, total: 0})
        const isMobile = inject('isMobile')
        const endpoint = store.state.url_backend
        const datos_filtro = ref({anunciante: '', anuncio: '', punto_wifi: '', desde: '', hasta: ''})
        const tipos_filtro = ref({anunciante: 'contiene', anuncio: 'contiene', punto_wifi: 'contiene', fecha: 'es', hora: 'es'})
        const filtrar = ref(false)
        const dato = ref({nombre_anunciante: null, nombre_anuncio: null, not_nombre_anuncio: null, nombre_puntowifi: null,
            not_nombre_puntowifi: null, fecha_desde: '', fecha_hasta: ''})
        const filtrado = ref(false)
        const total = ref(0)
        const preload = ref(true)


        const prevComp = computed(() => {
            return{
                name : route.name,
                params: {page: info.value.prev || route.params.page}
            }
        })
        const nextComp = computed(() => {
            return{
                name: route.name,
                params: {page: info.value.next || route.params.page}
            }
        })
        const ultimo = computed(() => {
            return{
                name: route.name,
                params: {page: info.value.pages}
            }
        })
        const primero = computed(() =>{
            return{
                name: route.name,
                params: {page: 1}
            }
        })

        const filtro_avanzado = () => {

            dato.value.nombre_anunciante = ''
            dato.value.nombre_anuncio = ''
            dato.value.nombre_puntowifi = ''
            dato.value.not_nombre_puntowifi = ''
            let desde = '1800-01-01 00:00:00'
            let hasta = '3000-12-31 00:00:00'

            if (datos_filtro.value.desde != '') {
                dato.value.fecha_desde = datos_filtro.value.desde + ' 00:00:00'
            } else {
                dato.value.fecha_desde = desde
            }

            if (datos_filtro.value.hasta != '') {
                dato.valuefecha_hasta = datos_filtro.value.hasta + ' 23:59:59'
            } else {
                dato.value.fecha_hasta = hasta
            }

            if (datos_filtro.value.anunciante != '') {
                dato.value.nombre_anunciante = datos_filtro.value.anunciante
            }

            if (datos_filtro.value.anuncio != '') {
                if (tipos_filtro.value.anuncio == 'contiene') {
                    dato.value.nombre_anuncio = datos_filtro.value.anuncio
                } else {
                    dato.value.not_nombre_anuncio = datos_filtro.value.anuncio
                }
            }

            if (datos_filtro.value.punto_wifi != '') {
                if (tipos_filtro.value.punto_wifi == 'contiene'){
                    dato.value.nombre_puntowifi = datos_filtro.value.punto_wifi
                } else {
                    dato.value.not_nombre_puntowifi = datos_filtro.value.punto_wifi
                }
            }

            consulta_filtro()            
        }

        const consulta_filtro = () => {

            if (datos_filtro.value.anunciante == '' && datos_filtro.value.anuncio == '' &&
            datos_filtro.value.punto_wifi == '' && datos_filtro.value.desde == '' && datos_filtro.value.hasta == '') {
                filtrado.value = false
            } else {
                filtrado.value = true
            }

            let desde = '1800-01-01 00:00:00'
            let hasta = '3000-12-31 00:00:00'

            if (datos_filtro.value.desde != '') {
                dato.value.fecha_desde = datos_filtro.value.desde + ' 00:00:00'
            } else {
                dato.value.fecha_desde = desde
            }

            if (datos_filtro.value.hasta != '') {
                dato.value.fecha_hasta = datos_filtro.value.hasta + ' 23:59:59'
            } else {
                dato.value.fecha_hasta = hasta
            }

            if (datos_filtro.value.anunciante != '') {
                dato.value.nombre_anunciante = datos_filtro.value.anunciante
            }

            if (datos_filtro.value.anuncio != '') {
                if (tipos_filtro.value.anuncio == 'contiene') {
                    dato.value.nombre_anuncio = datos_filtro.value.anuncio
                } else {
                    dato.value.not_nombre_anuncio = datos_filtro.value.anuncio
                }
            }

            if (datos_filtro.value.punto_wifi != '') {
                if (tipos_filtro.value.punto_wifi == 'contiene'){
                    dato.value.nombre_puntowifi = datos_filtro.value.punto_wifi
                } else {
                    dato.value.not_nombre_puntowifi = datos_filtro.value.punto_wifi
                }
            }

            if (filtrar.value) {
                filtrar.value = false
                router.push({name: 'TableroImpresiones', params: {page:1}})
            }

            total_impresiones()

            watchEffect(() => {

                const client = new GraphQLClient(endpoint)

                let arreglo = []
                client.rawRequest(/* GraphQL */ `
                query($first:Int!, $page: Int, $nombre_anunciante: String, $nombre_anuncio: String, $not_nombre_anuncio: String, $nombre_puntowifi: String,
                    $not_nombre_puntowifi: String, $fecha_desde: DateTime, $fecha_hasta: DateTime){
                    impresionsFilter(first: $first, page: $page, input:{
                        nombre_anunciante: $nombre_anunciante,
                        nombre_anuncio: $nombre_anuncio,
                        not_nombre_anuncio: $not_nombre_anuncio,
                        nombre_puntowifi: $nombre_puntowifi,
                        not_nombre_puntowifi: $not_nombre_puntowifi,
                        fecha_desde: $fecha_desde,
                        fecha_hasta: $fecha_hasta}) {
                        paginatorInfo{
                            count
                            currentPage
                            hasMorePages
                            lastPage
                            total
                        }
                        data {
                            id,
                            impresion,
                            anunciante_nombre,
                            anuncio_nombre,
                            puntowifi_nombre
                        }
                    }
                }`,
                {
                    first: parseInt(mostrar_cantidad.value),
                    page: parseInt(route.params.page),
                    nombre_anunciante: dato.value.nombre_anunciante,
                    nombre_anuncio: dato.value.nombre_anuncio,
                    not_nombre_anuncio: dato.value.not_nombre_anuncio,
                    nombre_puntowifi: dato.value.nombre_puntowifi,
                    not_nombre_puntowifi: dato.value.not_nombre_puntowifi,
                    fecha_desde: dato.value.fecha_desde,
                    fecha_hasta: dato.value.fecha_hasta,
                },
                {
                    authorization: `Bearer ${ localStorage.getItem('user_token') }`
                })
                .then((data) => {
                    // console.log(data)

                    data.data.impresionsFilter.data.forEach(element => {
                        arreglo.push({id: element.id, anuncio: element.anuncio_nombre, anunciante: element.anunciante_nombre,  punto_wifi: element.puntowifi_nombre, fyh_impresion: element.impresion.split(' ')})
                    })
                    impresiones.value = arreglo
                    let pageInfo = data.data.impresionsFilter.paginatorInfo
                    store.commit("paginacion", {pageInfo: pageInfo, info: info.value})
                    preload.value = false                    

                })
                .catch(error =>{
                    // console.log(error.response)
                })
            })


        }

        const total_impresiones = () => {
            const client = new GraphQLClient(endpoint)
            client.rawRequest(/* GraphQL */ `
            query($first: Int!){
                impresions(first: $first) {
                    paginatorInfo {
                        total
                    }
                    data {
                        id
                    }
                }
            }`,
            {
                first: 9999999
            },{
                authorization: `Bearer ${ localStorage.getItem('user_token') }`
            })
            .then((data) => {
                total.value = data.data.impresions.paginatorInfo.total
            })
            .catch(error  => {
                // console.log(error)
            })
        }


        const filtro = () => {
            if (!filtrado.value) {
                limpiarFiltro()
            }
            filtrar.value = true
        }

        const limpiarFiltro = () => {
            datos_filtro.value = {anunciante: '', anuncio: '', punto_wifi: '', desde: '', hasta: ''}
            tipos_filtro.value = {anunciante: 'contiene', anuncio: 'contiene', punto_wifi: 'contiene', fecha: 'es', hora: 'es'}
            buscar.value = ''
        }

        watch(mostrar_cantidad, () => {
            consulta_filtro()
        })

        document.addEventListener('click', function(e) {
            let clic = e.target.getAttribute("id")

            if (clic == 'modal-background') {
                filtrar.value = false
            }

        }, false)

        // watch(buscar, () => {
        //     filtro_buscador()
        // })

        // const filtro_buscador = () => {
        //     let arreglo_aux = []
        //     impresiones_aux.value = Allimpresion.value
        //     if (buscar.value != '') {
        //         impresiones_aux.value.filter((impresion)=>{
        //             let dato =  impresion.id.match(buscar.value)
        //             || impresion.anuncio.toLowerCase().match(buscar.value.toLowerCase())
        //             || impresion.punto_wifi.toLowerCase().match(buscar.value.toLowerCase())

        //             if (dato != null) {
        //                 arreglo_aux.push(impresion)
        //             }

        //         })
        //         impresiones_aux.value = arreglo_aux
        //     } else {
        //         impresiones_aux.value = Allimpresion.value
        //     }
        //     pageCont.value = Math.ceil(impresiones_aux.value.length/paginaInfo.value.pageSize)
        //     totalElement.value = impresiones_aux.value.length
        //     paginaInfo.value = {page: 1, pageSize: mostrar_cantidad.value, prev: null, next: 0}
        //     if (pageCont.value > 1) {
        //         paginaInfo.value.next = paginaInfo.value.page +1
        //     }
        //     showImpresiones(impresiones_aux.value)
        // }



        return{
            impresiones,
            impresiones_aux,
            isMobile,
            buscar,
            info,
            mostrar_cantidad,
            todo,
            datos_filtro,
            tipos_filtro,
            filtro,
            filtrar,
            filtro_avanzado,
            limpiarFiltro,
            consulta_filtro,
            total_impresiones,


            prevComp,
            nextComp,
            ultimo,
            primero,
            total,
            filtrado,
            preload
        }
    }
}
</script>

<style scoped>


.seleccionado{
    background-color: #01AC97;
}
.seleccionado:hover{
    background-color: #008575;
}

.deseleccionado:hover{
    background-color: #EDEDED;
}
.fondo-fila{
    background-color: rgb(223, 219, 219);
}


</style>