<template>
    <div class="container">
        <div class="column">
            <div class="columns">
                <div v-show="!isMobile" class="columns  pt-3">
                    <!-- // probando -->
                    <div class="column  mr-0 pr-0">
                           <button class="button is-static has-text-black" >Mostrar</button>
                    </div>

                    <div class="column  pl-0 control" v-tooltip="'Filas'">
                            <div class="select ">
                                <select class="options" v-model="mostrar_cantidad">
                                    <option value="10" >10</option>
                                    <option value="50" >50</option>
                                    <option value="100">100</option>
                                    <option value="200">200</option>
                                    <option value="500">500</option>
                                </select>
                            </div>
                    </div>
                </div>
                <div v-show="isMobile" class="columns has-text-right is-mobile pt-3">
                    <div class="column  mr-0 pr-0 has-text-right">
                        <button class="button is-static has-text-black">Mostrar</button>
                    </div>
                    <div class="column  has-text-left control">
                        <div class="select ">
                            <select class="options" v-model="mostrar_cantidad">
                                <option value="10" >10</option>
                                <option value="50" >50</option>
                                <option value="100">100</option>
                                <option value="100">200</option>
                                <option value="100">500</option>
                            </select>
                        </div>
                    </div>
                </div>
                 <div v-show="!isMobile"  class="column  has-text-center">
                    <!-- Buscador -->
                    <div class=" field ">
                        <p class="control has-icons-left " style="width:20.8rem">
                            <input class="input " type="text" placeholder="Buscar anunciante" v-model="buscar">
                            <span class="icon is-left">
                                <i class="fas fa-search" ></i>
                            </span>
                        </p>
                    </div>
                </div>
                <div v-show="isMobile"  class="column  has-text-center ml-3">
                    <!-- Buscador -->
                    <div class=" field ">
                        <p class="control has-icons-left " style="width:20.8rem">
                            <input class="input " type="text" placeholder="Buscar anunciante" v-model="buscar">
                            <span class="icon is-left">
                                <i class="fas fa-search" ></i>
                            </span>
                        </p>

                    </div>
                </div>
                <div class="column is-5">
                    <div class="buttons is-justify-content-flex-end">
                        <div class="dropdown pr-2" :class="{'is-active':mas_opciones}" >
                            <div id="mas-opciones" class="dropdown-trigger">
                                <button id="mas-opciones" class="button has-background-info has-text-white" aria-haspopup="true" aria-controls="dropdown-menu" @click="mas_opciones = !mas_opciones">
                                    <span id="mas-opciones">Mas opciones</span>
                                    <span id="mas-opciones" class="icon is-small">
                                        <i id="mas-opciones" class="fas fa-angle-down" aria-hidden="true"></i>
                                    </span>
                                </button>
                            </div>
                            <div class="dropdown-menu pt-0" id="dropdown-menu" role="menu">
                                <div class="dropdown-content">
                                    <a id="mas-opciones" class="dropdown-item" @click="envio_adv_masivo">
                                        Enviar emails
                                    </a>
                                    <a id="mas-opciones" class="dropdown-item" @click="eliminar_adv_masivo">
                                        Eliminación masiva
                                    </a>
                                </div>
                            </div>
                        </div>

                        <button class="button has-background-info has-text-white" @click="filtro">Filtro</button>

                        <div>
                            <div class="modal" :class="{'is-active': filtrar}">
                                <div id="modal-background" class="modal-background " style="background-color: rgb(197, 197, 197, 0.1)"></div>
                                <div class="modal-content-width">
                                    <div class="container has-text-centered has-background-white p-3" :class="{'p-5':isMobile}" id="modal">
                                        <div class="columns">
                                            <div class="column is-8 pb-0" :class="{'ml-5':!isMobile}">
                                                <h1 class="is-size-3 has-text-black has-text-weight-bold pt-3" :class="{'ml-5':!isMobile}">Filtrar tabla</h1>
                                            </div>
                                            <div class="column" :class="{'mt-3 pr-1':!isMobile}">
                                                <button class="button verde-puwic mb-0 has-text-white has-text-weight-bold" :class="{'mr-1':!isMobile, 'ml-3':isMobile}" @click="limpiarFiltro">Limpiar</button>
                                            </div>
                                        </div>

                                        <div :class="{'modal-scrolleable' : isMobile}">

                                            <!-- Nombre -->
                                            <div class="field columns" :class="{'mb-0 pt-1' : isMobile, 'mt-2': !isMobile}">
                                                <div class="column is-one-fifth ml-4 pt-2">
                                                    <label class="label" :class="{'has-text-right': !isMobile}" >Nombre</label>
                                                </div>
                                                <div class="columns has-text-centered ml-0 mr-0">
                                                    <div class="control column is-flex-grow-0" :class="{'py-1' : isMobile}">
                                                        <div class="select" >
                                                            <select class="options" v-model="tipo_filtro.nombre">
                                                                <option value="contiene">contiene</option>
                                                                <option value="no contiene">no contiene</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="control column" :class="{'pt-1' : isMobile}">
                                                        <input class="input" v-model="datos_filtro.nombre" name="nombre" type="text">
                                                    </div>

                                                </div>
                                            </div>

                                            <!-- Telefono -->
                                            <div class="field columns" :class="{'my-0': isMobile, 'mt-2': !isMobile}">
                                                <div class="column is-one-fifth ml-4 pt-2" style="min-width: 50px">
                                                    <label class="label" :class="{'has-text-right': !isMobile}">Teléfono</label>
                                                </div>
                                                <div class="columns has-text-centered ml-0 mr-0">
                                                    <div class="control column is-flex-grow-0" :class="{'py-1': isMobile}">
                                                        <div class="select" style="width: 139px">
                                                            <select class="options" v-model="tipo_filtro.telefono" style="width: 139px">
                                                                <option value="es">es</option>
                                                                <option value="mayor">mayor a</option>
                                                                <option value="menor">menor a</option>
                                                                <option value="vacio">vacio</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="control column" :class="{'pt-1': isMobile}">
                                                        <input class="input" v-model="datos_filtro.telefono" name="telefono" type="text">
                                                    </div>

                                                </div>
                                            </div>

                                            <!-- Domicilio -->
                                            <div class="field columns mt-0" :class="{'mb-0': isMobile}">
                                                <div class="column is-one-fifth ml-4 pt-2" style="min-width: 50px">
                                                    <label class="label" :class="{'has-text-right': !isMobile}">Domicilio</label>
                                                </div>
                                                <div class="columns has-text-centered ml-0 mr-0">
                                                    <div class="control column is-flex-grow-0" :class="{'py-1': isMobile}">
                                                        <div class="select" >
                                                            <select class="options" v-model="tipo_filtro.domicilio">
                                                                <option value="contiene">contiene</option>
                                                                <option value="no contiene">no contiene</option>
                                                                <option value="vacio">vacio</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="control column" :class="{'pt-1': isMobile}">
                                                        <input class="input" v-model="datos_filtro.domicilio" name="domicilio" type="text">
                                                    </div>

                                                </div>
                                            </div>

                                            <!-- Ciudad -->
                                            <div class="field columns" :class="{'my-0': isMobile, 'mt-2': !isMobile}">
                                                <div class="column is-one-fifth ml-4 pt-2" style="min-width: 50px">
                                                    <label class="label" :class="{'has-text-right': !isMobile}">Ciudad</label>
                                                </div>
                                                <div class="columns has-text-centered ml-0 mr-0">
                                                    <div class="control column is-flex-grow-0" :class="{'py-1': isMobile}">
                                                        <div class="select" >
                                                            <select class="options" v-model="tipo_filtro.ciudad">
                                                                <option value="contiene">contiene</option>
                                                                <option value="no contiene">no contiene</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="control column" :class="{'pt-1': isMobile}">
                                                        <input class="input" v-model="datos_filtro.ciudad" name="ciudad" type="text">
                                                    </div>

                                                </div>
                                            </div>

                                            <!-- Correo -->
                                            <div class="field columns" :class="{'my-0': isMobile, 'mt-2': !isMobile}">
                                                <div class="column is-one-fifth ml-4 pt-2" style="min-width: 50px">
                                                    <label class="label" :class="{'has-text-right': !isMobile}">Email</label>
                                                </div>
                                                <div class="columns has-text-centered ml-0 mr-0">
                                                    <div class="control column is-flex-grow-0" :class="{'py-1': isMobile}">
                                                        <div class="select" >
                                                            <select class="options" v-model="tipo_filtro.correo">
                                                                <option value="contiene">contiene</option>
                                                                <option value="no contiene">no contiene</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="control column" :class="{'pt-1': isMobile}">
                                                        <input class="input" v-model="datos_filtro.correo" name="correo" type="text">
                                                    </div>

                                                </div>
                                            </div>

                                            <!-- Comentario -->
                                            <div class="field columns" :class="{'my-0': isMobile, 'mt-0': !isMobile}">
                                                <div class="column is-one-fifth ml-4 pt-2" style="min-width: 50px">
                                                    <label class="label" :class="{'has-text-right': !isMobile}">Comentario</label>
                                                </div>
                                                <div class="columns has-text-centered ml-0 mr-0">
                                                    <div class="control column is-flex-grow-0" :class="{'py-1': isMobile}">
                                                        <div class="select" >
                                                            <select class="options" v-model="tipo_filtro.comentario">
                                                                <option value="contiene">contiene</option>
                                                                <option value="no contiene">no contiene</option>
                                                                <option value="vacio">vacio</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="control column" :class="{'pt-1': isMobile}">
                                                        <input class="input" v-model="datos_filtro.comentario" name="ciudad" type="text">
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Botones -->
                                            <div class="columns">
                                                <div class="column" :class="{'ml-5': !isMobile}">
                                                    <div class="buttons">
                                                        <button class="button has-background-danger is-outlined btn has-text-white has-text-weight-bold" style="width: 30%" @click="filtrar = false">Cancelar</button>
                                                        <button class="button verde-puwic is-outlined btn has-text-white has-text-weight-bold boton-tamaño" @click="filtro_avanzado">Filtro</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <router-link :to="{name: 'AgregarAnunciante'}" class="button has-background-link has-text-white">Agregar anunciante</router-link> -->
                        <a @click="verificarHabilitado('AgregarAnunciante')" class="button has-background-link has-text-white">Agregar anunciante</a>
                    </div>
                </div>
            </div>
        </div>

        <button class="total-element">Total: {{Allanunciantes.length}}</button>
        <button v-show="filtrado" class="total-element mx-0 pointer" @click="filtro">Total filtrado: {{totalElement}}</button>


        <div class="column table-container">
            <table class="table  has-background-white-ter" style="width: 100%">
                <thead>
                    <tr>
                    <th>
                        <div v-show="todo">
                            <button class="button is-small is-light"  @click="marcar_todo">
                                    <span class="icon-text">
                                        <span class="icon is-size-6">
                                            <i class="far fa-check-square"></i>
                                        </span>
                                    </span>
                            </button>
                        </div>
                        <div v-show="!todo">
                            <button class="button is-small is-light"  @click="marcar_todo">
                                <span class="icon-text">
                                    <span class="icon is-size-6">
                                        <i class="far fa-square"></i>
                                    </span>
                                </span>
                            </button>
                        </div>
                     </th>
                    <th>
                        <button @click="ordenar('nombre')" class="button  has-text-weight-medium has-text-black is-light px-0 py-0 ">
                            Nombre
                            <div  v-show="clics.n1!=false">
                                <span  v-show="ordenarNombre!=false">
                                    <i class="fas fa-arrow-up"></i>
                                </span>
                                <span v-show="ordenarNombre==false">
                                    <i class="fas fa-arrow-down"></i>
                                </span>
                            </div>
                        </button>
                    </th>
                    <th>
                        <button @click="ordenar('telefono')" class="button  has-text-weight-medium has-text-black is-light px-0 py-0 ">
                            Teléfono
                            <div  v-show="clics.n2!=false">
                                <span  v-show="ordenarTelefono!=false">
                                    <i class="fas fa-arrow-up"></i>
                                </span>
                                <span v-show="ordenarTelefono==false">
                                    <i class="fas fa-arrow-down"></i>
                                </span>
                            </div>
                        </button>
                    </th>
                    <th>
                        <button @click="ordenar('domicilio')" class="button  has-text-weight-medium has-text-black is-light px-0 py-0 ">
                            Domicilio
                            <div  v-show="clics.n3!=false">
                                <span  v-show="ordenarDomicilio!=false">
                                    <i class="fas fa-arrow-up"></i>
                                </span>
                                <span v-show="ordenarDomicilio==false">
                                    <i class="fas fa-arrow-down"></i>
                                </span>
                            </div>
                        </button>
                    </th>
                    <th>
                        <button @click="ordenar('ciudad')" class="button  has-text-weight-medium has-text-black is-light px-0 py-0 ">
                            Ciudad
                            <div  v-show="clics.n4!=false">
                                <span  v-show="ordenarCiudad!=false">
                                    <i class="fas fa-arrow-up"></i>
                                </span>
                                <span v-show="ordenarCiudad==false">
                                    <i class="fas fa-arrow-down"></i>
                                </span>
                            </div>
                        </button>
                    </th>
                    <th>
                        <button @click="ordenar('correo')" class="button  has-text-weight-medium has-text-black is-light px-0 py-0 ">
                            Email
                            <div  v-show="clics.n5!=false">
                                <span  v-show="ordenarCorreo!=false">
                                    <i class="fas fa-arrow-up"></i>
                                </span>
                                <span v-show="ordenarCorreo==false">
                                    <i class="fas fa-arrow-down"></i>
                                </span>
                            </div>
                        </button>
                    </th>
                    <!-- <th>
                        <button @click="ordenar('correo_alt')" class="button  has-text-weight-medium has-text-black is-light px-0 py-0 ">
                            Correo alternativo
                            <div  v-show="clics.n6!=false">
                                <span  v-show="ordenarCorreoAlternativo!=false">
                                    <i class="fas fa-arrow-up"></i>
                                </span>
                                <span v-show="ordenarCorreoAlternativo==false">
                                    <i class="fas fa-arrow-down"></i>
                                </span>
                            </div>
                        </button>
                    </th> -->
                    <th>
                        <button @click="ordenar('comentario')" class="button  has-text-weight-medium has-text-black is-light px-0 py-0 ">
                            Comentario
                            <div  v-show="clics.n7!=false">
                                <span  v-show="ordenarComentario!=false">
                                    <i class="fas fa-arrow-up"></i>
                                </span>
                                <span v-show="ordenarComentario==false">
                                    <i class="fas fa-arrow-down"></i>
                                </span>
                            </div>
                        </button>
                    </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(anunciante,index) in anunciantes" :index="index" :key="anunciante.id" :class="{'fondo-fila':!anunciante.tildado && index%2==0,'seleccionado': anunciante.tildado, 'deseleccionado': !anunciante.tildado}">
                        <td>
                            <input v-if="!anunciante.tildado" @click="tildar(anunciante)" class="ml-2" type="checkbox">
                            <input v-else @click="tildar(anunciante)" class="ml-2" checked type="checkbox">
                        </td>
                        <td @click="modal(anunciante)">{{anunciante.nombre}}</td>
                        <td @click="modal(anunciante)">{{anunciante.telefono}}</td>
                        <td @click="modal(anunciante)">{{anunciante.domicilio}}</td>
                        <td @click="modal(anunciante)">{{anunciante.ciudad}}</td>
                        <td @click="modal(anunciante)">{{anunciante.correo}}</td>
                        <!-- <td @click="modal(anunciante)">{{anunciante.correo_alt}}</td> -->
                        <td @click="modal(anunciante)">{{anunciante.comentario}}</td>

                        <div>
                            <div class="modal" :class="{'is-active': anunciante.activo}" @click="anunciante.activo = false">
                                <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.1)"></div>
                                <div class="modal-content-width" style="border:1px ridge grey;">
                                    <div class="container has-text-centered has-background-white" style="width: 200px" id="modal">
                                        <div class="column p-0 py-1 pt-4 px-4">
                                            <!-- <router-link :to="{name: 'EdicionAnunciante', params:{id: anunciante.id}}" class="button verde-puwic is-outlined btn has-text-white has-text-weight-bold" style="width: 100%">Editar</router-link> -->
                                            <a @click="verificarHabilitadoParam('EdicionAnunciante', anunciante.id)" class="button verde-puwic is-outlined btn has-text-white has-text-weight-bold" style="width: 100%">Editar</a>
                                        </div>
                                        <div class="column p-0 pb-4 px-4">
                                            <button class="button has-background-danger is-outlined btn has-text-white has-text-weight-bold" style="width: 100%" @click="eliminar_adv(anunciante)">Eliminar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div>
                            <div class="modal" :class="{'is-active': mostrar_advertencia}" >
                                <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.0)"></div>
                                <div class="modal-content-width" style="border:1px ridge grey;">
                                    <div class="container has-text-centered has-background-white px-1 py-1" style="width: 320px" id="modal">
                                        <h1 class="is-size-3 has-text-weight-semibold has-text-danger" :class="{'is-size-4':isMobile}">¿Estas seguro?</h1>

                                        <p v-show="opsIndividual==true" class="has-text-centered ">Quiere eliminar al anunciante {{anunciante_elegido.nombre}}. Esta acción no se podra revertir</p>
                                        <p v-show="opsMasivo==true" class="has-text-centered ">Se eliminaran a los anunciantes seleccionados. Esta accion no se puede revertir.</p>
                                        <p v-show="opsEnvio==true && opsMasivo==false" class="has-text-centered ">Se enviaran correos a los anunciantes seleccionados. </p>
                                        <div class="columns   py-1 pt-4 px-1">
                                            <div class="column ">
                                                <button class="button w-100 has-background-danger is-outline btn has-text-white has-text-weight-blod" @click="cancelar">Cancelar</button>
                                            </div>
                                            <div v-show="opsEnvio==false " class="column">
                                                <button class="button w-100 verde-puwic is-outline btn has-text-white has-text-weight-blod" @click="confirmar">Si,eliminar</button>
                                            </div>
                                            <div v-show="opsEnvio==true && opsMasivo==false && opsIndividual==false" class="column">
                                                <button class="button w-100 verde-puwic is-outline btn has-text-white has-text-weight-blod" @click="confirmar">Si,enviar correos</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="modal" :class="{'is-active': mostrar_aviso}">
                                <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.0)"></div>
                                <div class="modal-content-width has-text-black" style="border:1px ridge grey;" :class="{'modal-puntowifi-escritorio' : !isMobile, 'modal-puntowifi-mobil' : isMobile}">
                                    <div class="container has-text-centered has-background-white" :class="{'p-2':isMobile, 'p-5':!isMobile}" id="modal">
                                        <!-- <h1 class="is-size-3 has-text-weight-semibold" :class="{'is-size-4':isMobile}">No se puede borrar el anuncio</h1> -->
                                        <p class="has-text-centered has-text-danger">No se puede eliminar el anunciante, porque tiene anuncios relacionados</p>
                                        <div class="columns mt-2">
                                            <div class="column">
                                                <button class="button w-100 verde-puwic is-outline btn has-text-white has-text-weight-blod" @click="mostrar_aviso = false">Esta bien</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="modal " :class="{'is-active': mostrar_correo_enviado}">
                                <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.0)"></div>
                                <div class="modal-content-width has-text-black" style="border:1px ridge grey; max-width:400px" :class="{'modal-puntowifi-escritorio' : !isMobile, 'modal-puntowifi-mobil para-cel' : isMobile}">
                                    <div class="container has-text-centered has-background-white"   :class="{'p-2':isMobile, 'p-5':!isMobile}" id="modal">
                                        <!-- <h1 class="is-size-3 has-text-weight-semibold" :class="{'is-size-4':isMobile}">No se puede borrar el anuncio</h1> -->
                                        <div v-if="verificacion_de_envio==true">
                                            <p  class="is-size-5 has-text-weight-semibold has-text-success" :class="{'is-size-5':isMobile}">{{mensaje_correo}}</p>
                                        </div>
                                        <div v-else>
                                            <p  class="is-size-5 has-text-weight-semibold has-text-danger" :class="{'is-size-5':isMobile}">{{mensaje_correo}}</p>
                                        </div>
                                        <div class="columns mt-2">
                                            <div class="column">
                                                <button class="button w-100 verde-puwic is-outline btn has-text-white has-text-weight-blod" @click="mostrar_correo_enviado = false">Esta bien</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="modal" :class="{'is-active': mostrar_validacion}">
                                <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.0)"></div>
                                <div class="modal-content-width has-text-black" style="border:1px ridge grey;" :class="{'modal-puntowifi-escritorio' : !isMobile, 'modal-puntowifi-mobil' : isMobile}">
                                    <div class="container has-text-centered has-background-white" :class="{'p-2':isMobile, 'p-5':!isMobile}" id="modal">
                                        <!-- <h1 class="is-size-3 has-text-weight-semibold" :class="{'is-size-4':isMobile}">No se puede borrar el anuncio</h1> -->
                                        <p class="has-text-centered has-text-danger">Se debe seleccionar algun anunciante para realizar la accion</p>
                                        <div class="columns mt-2">
                                            <div class="column">
                                                <button class="button w-100 verde-puwic is-outline btn has-text-white has-text-weight-blod" @click="mostrar_validacion = false">Esta bien</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="modal" :class="{'is-active': carga_exitosa}">
                                <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.0)"></div>
                                <div class="modal-content-width has-text-black" style="border:1px ridge grey;" :class="{'modal-puntowifi-escritorio' : !isMobile, 'modal-puntowifi-mobil' : isMobile}">
                                    <div class="container has-text-centered has-background-white" :class="{'p-2':isMobile, 'p-5':!isMobile}" id="modal">
                                        <!-- <h1 class="is-size-3 has-text-weight-semibold" :class="{'is-size-4':isMobile}">No se puede borrar el anuncio</h1> -->
                                        <p v-show="comprobar==true" class="has-text-centered has-text-success">Se cargo con exito al anunciante.</p>
                                        <p v-show="comprobar_edi==true" class="has-text-centered has-text-success">Se edito con exito al anunciante.</p>
                                        <div class="columns mt-2">
                                            <div class="column">
                                                <button class="button w-100 verde-puwic is-outline btn has-text-white has-text-weight-blod" @click="carga_exitosa = false">Esta bien</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="modal" :class="{'is-active': accion_exitosa}">
                                <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.0)"></div>
                                <div class="modal-content-width has-text-black" style="border:1px ridge grey;" :class="{'modal-puntowifi-escritorio' : !isMobile, 'modal-puntowifi-mobil' : isMobile}">
                                    <div class="container has-text-centered has-background-white" :class="{'p-2':isMobile, 'p-5':!isMobile}" id="modal">
                                        <!-- <h1 class="is-size-3 has-text-weight-semibold" :class="{'is-size-4':isMobile}">No se puede borrar el anuncio</h1> -->
                                        <p v-show="paso_elim==true" class="has-text-centered has-text-success">Se elimino los anunciantes seleccionados exitosamente.</p>
                                        <p v-show="paso_elim==false" class="has-text-centered has-text-danger">No se logro eliminar a los anunciantes seleccionados.</p>
                                        <div class="columns mt-2">
                                            <div class="column">
                                                <button class="button w-100 verde-puwic is-outline btn has-text-white has-text-weight-blod" @click="accion_exitosa = false">Esta bien</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </tr>
                </tbody>
            </table>

            <div v-show="preload" class="loader-wrapper is-active-loading">
                <div class="loader is-loading "></div>
            </div>

            <div v-if="!preload && anunciantes.length == 0">
                <h1 class="has-text-centered is-size-4 has-text-grey">No hay anunciantes</h1>
            </div>
        </div>
        <nav v-show="totalElement > mostrar_cantidad" class="pagination is-right" role="navigation" aria-label="pagination">
            <button v-if="paginaInfo.page > 1" class="pagination-previous" @click="prevPage">Anterior</button>
            <button v-else class="pagination-previous" disabled >Anterior</button>
            <button v-if="paginaInfo.page < pageCont" class="pagination-next" @click="nextPage">Siguiente</button>
            <button v-else class="pagination-next" disabled>Next</button>
            <ul class="pagination-list" style="justify-content: center">
                <li v-show="paginaInfo.prev > 1"> <button class="pagination-link" @click="inicio">1</button></li>
                <li v-show="paginaInfo.prev > 2"><span class="pagination-ellipsis">&hellip;</span></li>
                <li v-show="paginaInfo.prev != null"><button class="pagination-link" @click="prevPage">{{paginaInfo.prev}}</button></li>
                <li><button class="pagination-link is-current">{{paginaInfo.page}}</button></li>
                <li v-show="paginaInfo.next != null && paginaInfo.next > 1"><button class="pagination-link" @click="nextPage">{{paginaInfo.next}}</button></li>
                <li v-show="paginaInfo.next < pageCont - 1 && paginaInfo.next != null"><span class="pagination-ellipsis">&hellip;</span></li>
                <li v-show="paginaInfo.next != null && (paginaInfo.next < pageCont) && (pageCont != 1)" ><button class="pagination-link" @click="final">{{pageCont}}</button></li>
            </ul>
        </nav>
    </div>
</template>

<script>
import {filtrar_campo_numeral} from '@/composables/filtrar_campo_numeral.js'
import {filtrar_campo_texto} from '@/composables/filtrar_campo_texto.js'
import {ordenar_arreglo} from '@/composables/ordenar_arreglo.js'
import store from '@/store';
import { GraphQLClient } from 'graphql-request'
import { inject, ref, watch, watchEffect } from 'vue'
import { useRoute, useRouter } from 'vue-router';
import {queryMe} from '@/composables/queryMe.js'


export default {


    created(){
        this.traerAnunciantes()
        this.anunciantes_aux = this.anunciantes
        this.comprobar_carga()
        this.comprobar_edicion()
    },

    setup(){
        const mostrar_correo_enviado = ref(false)
        const mostrar_cantidad = ref(10)
        const todo = ref(false)
        const isMobile = inject('isMobile')
        const anunciantes = ref([])
        const anunciantes_aux = ref([])
        const buscar = ref('')
        const filtrar = ref(false)
        const datos_filtro = ref({nombre: '', telefono: '', domicilio: '', ciudad: '', correo: '', correo_alt: '', comentario: ''})
        const tipo_filtro = ref({nombre: 'contiene', telefono:'es', domicilio: 'contiene', ciudad: 'contiene', correo: 'contiene', correo_alt: 'contiene', comentario: 'contiene'})
        const info = ref({count:0, pages:0, next:0, prev:0, page:0, total: 0})
        const mas_opciones = ref(false)
        const endpoint = store.state.url_backend
        const clics = ref({n1:false,n2:false,n3:false, n4:false,n5:false,n6:false,n7:false})
        const ordenarNombre = ref(false)
        const ordenarTelefono = ref(false)
        const ordenarDomicilio= ref(false)
        const ordenarCiudad = ref(false)
        const ordenarCorreo= ref(false)
        const ordenarCorreoAlternativo = ref(false)
        const ordenarComentario = ref(false)
        const eliminarAnunciantes = ref([])
        const enviarAnunciantes = ref([])
        const route =  useRoute()
        const router = useRouter()
        const Allanunciantes = ref([])
        const paginaInfo = ref({page: 1, pageSize: parseInt(mostrar_cantidad.value), prev: null, next: 0})
        const pageCont = ref(0) // capaz tenga que agregarlo en la consulta de datos
        const totalElement = ref(0)
        const anunciante_elegido = ref([{id:null,nombre:''}])
        const mostrar_advertencia = ref(false)
        const mostrar_aviso = ref(false)
        const opsMasivo = ref(false)
        const opsEnvio = ref(false)
        const opsIndividual  = ref(false)
        const cant_elegida = ref(null)
        const mostrar_nombre = ref('')
        const verificacion_de_envio = ref(false)
        const mensaje_correo = ref('')
        const filtrado = ref(false)
        const mostrar_validacion = ref(false)
        const preload = ref(true)
        const carga_exitosa = ref(false)
        const comprobar = store.state.carga_exitosa_ciudad
        const comprobar_edi = store.state.edicion_exitosa
        const accion_exitosa = ref(false)
        const paso_elim = ref(false)


        const comprobar_carga = () => {

            // console.log(comprobar)
            if(comprobar==true){
               carga_exitosa.value = true
               let accion = "nuevoanunciante"
               store.commit('verificar_carga',accion)
            }

        }
        const comprobar_edicion = () => {

            // console.log(comprobar)
            if(comprobar_edi==true){
               carga_exitosa.value = true
               let accion = "edicionanunciante"
               store.commit('verificar_carga',accion)
            }

        }

        const verificarHabilitado = async (name) => {
            let habilitado = await queryMe()
            // console.log(name)
            if (habilitado) {
                router.push({name: name})
            } else {
                router.push({name: 'Login'})
            }
        }

        const verificarHabilitadoParam = async (name, params) => {
            let habilitado = await queryMe()
            // console.log(name)
            if (habilitado) {
                router.push({name: name, params: {id: params} })
            } else {
                router.push({name: 'Login'})
            }
        }

        window.addEventListener("resize", function(){
            store.commit("onResize")
            isMobile.value = store.state.isMobile
        })

        const eliminar_masivamente = () => {
                // console.log("entro a eliminar masivamente")
                anunciantes.value.forEach(element => {
                    if (element.tildado == true) {
                        eliminarAnunciantes.value.push({id: element.id})
                    }
                })
                // console.log(eliminarAnunciantes.value)
                eliminarAnunciantes.value.forEach(element => {
                    eliminar(element.id)
                })

        }

        const eliminar_adv_masivo = () => {

            // console.log("cantidad de anunciantes",anunciantes_aux.value)
            let cont = 0
            anunciantes.value.forEach(element => {
                if(element.tildado){
                        cont += 1
                }
            })
            // console.log(cont)

            if(cont!=0){
                mostrar_validacion.value = false
                opsMasivo.value =  true
                opsIndividual.value = false
                opsEnvio.value =  false
                eliminarAnunciantes.value = []
                mostrar_advertencia.value = !mostrar_advertencia.value
            }else{
                mostrar_validacion.value = true
            }

        // console.log(eliminarCiudades.value.length)
        }

        const envio_adv_masivo = () => {

            let cont = 0
            anunciantes.value.forEach(element => {
                if(element.tildado){
                        cont += 1
                }
            })
            if(cont!=0){
                mostrar_validacion.value = false
                opsMasivo.value =  false
                opsIndividual.value = false
                opsEnvio.value =  true
                enviarAnunciantes.value = []
                mostrar_advertencia.value = !mostrar_advertencia.value
            }else{
                mostrar_validacion.value = true
            }
        // console.log(eliminarCiudades.value.length)
        }

        const eliminar_adv = (anunciante) => {
                    opsIndividual.value = true
                    opsEnvio.value =  false
                    opsMasivo.value =  false
                    mostrar_advertencia.value = !mostrar_advertencia.value
                    anunciante_elegido.value.id = anunciante.id
                    anunciante_elegido.value.nombre = anunciante.nombre
                    // console.log(ciudadelegida.value.id)
                    // console.log(ciudadelegida.value.nombre)
        }

        const confirmar = () => {

            mostrar_advertencia.value = !mostrar_advertencia.value
            if(opsMasivo.value==true){
                // console.log("Entro en confirmar de eliminacion masiva")
                eliminar_masivamente()
                eliminarAnunciantes.value = []
                // console.log(eliminarPuntos.value)
            }
            if(opsEnvio.value==true){
                mostrar_correo_enviado.value =  !mostrar_correo_enviado.value
                enviar_correo()
                enviarAnunciantes.value = []
                // console.log(eliminarPuntos.value)
            }
            if(opsIndividual.value==true){
                eliminar(anunciante_elegido.value.id)
            }


        }

        const cancelar = () => {
            mostrar_advertencia.value = !mostrar_advertencia.value
        }


        watchEffect(() => {
            const client = new GraphQLClient(endpoint) // creamos la consulta para usarlo luego

            client.rawRequest(/* GraphQL */ `
            query($first:Int!){
                anunciantes(first: $first){
                    paginatorInfo{
                        count,
                        currentPage,
                        lastPage,
                        perPage
                        total
                    }
                    data{
                        id
                        nombre
                        telefono
                        email
                        email_alternativo
                        domicilio
                        municipio{
                            id,
                            nombre
                            }
                        comentario
                    }
                }
            }`,
            {
                first: 99999
            },
            {
                authorization: `Bearer ${ localStorage.getItem('user_token') }`
            })
            .then( async (data) => {

                totalElement.value = data.data.anunciantes.paginatorInfo.total

                await data.data.anunciantes.data.forEach(element => {
                    Allanunciantes.value.push({id:element.id, nombre: element.nombre, correo: element.email, correo_alt: element.email_alternativo,
                    telefono: element.telefono ,comentario: element.comentario+'',domicilio: element.domicilio,ciudad: element.municipio.nombre, activo: false, tildado: false})
                })
                pageCont.value = Math.ceil(Allanunciantes.value.length/paginaInfo.value.pageSize)

                if (pageCont.value > 1) {
                    paginaInfo.value.next = paginaInfo.value.page + 1
                }

                anunciantes_aux.value = Allanunciantes.value
                showImpresiones(anunciantes_aux.value)
                preload.value = false

            }).catch(error => {
                // console.log(error.response);
            })
        })

        const paginate = (array, page_size, page_number) => {
            return array.slice((page_number - 1) * page_size, page_number * page_size)
        }

        const nextPage = () => {
            paginaInfo.value.page++
            if (paginaInfo.value.page >= pageCont.value) {
                paginaInfo.value.next = null
                paginaInfo.value.prev = paginaInfo.value.page - 1
            } else {
                paginaInfo.value.next = paginaInfo.value.page + 1
                paginaInfo.value.prev = paginaInfo.value.page - 1
            }
            showImpresiones(anunciantes_aux.value)
        }

        const prevPage = () => {
            paginaInfo.value.page--
            if (paginaInfo.value.page <= 1) {
                paginaInfo.value.prev = null
                paginaInfo.value.next = paginaInfo.value.page + 1
            } else {
                paginaInfo.value.prev = paginaInfo.value.page - 1
                paginaInfo.value.next = paginaInfo.value.page + 1
            }
            showImpresiones(anunciantes_aux.value)
        }

        const inicio = () => {
            paginaInfo.value.page = 1
            paginaInfo.value.prev = null
            if (paginaInfo.value.page >= pageCont.value) {
                paginaInfo.value.next = null
            } else {
                paginaInfo.value.next = paginaInfo.value.page + 1
            }
            showImpresiones(anunciantes_aux.value)
        }

        const final = () => {
            paginaInfo.value.page = pageCont.value
            paginaInfo.value.next = null
            if (paginaInfo.value.page <= 1) {
                paginaInfo.value.prev = null
            } else {
                paginaInfo.value.prev = paginaInfo.value.page - 1
            }
            showImpresiones(anunciantes_aux.value)
        }

        const showImpresiones = (array) => {
            anunciantes.value  = paginate(array, paginaInfo.value.pageSize, paginaInfo.value.page)
        }

        watch(mostrar_cantidad, () => {
            paginaInfo.value = {page: 1, pageSize: parseInt(mostrar_cantidad.value), prev: null, next: 0}
            // console.log(paginaInfo.value.pageSize)
            pageCont.value = Math.ceil(anunciantes_aux.value.length/paginaInfo.value.pageSize)
            // console.log(pageCont.value)
            if (pageCont.value > 1) {
                paginaInfo.value.next = paginaInfo.value.page +1
            }
            showImpresiones(anunciantes_aux.value)
        })

        watch(buscar, () => {
            filtro_buscador()
        })

        const filtro_buscador = () => {
            let arreglo_aux = []
            anunciantes_aux.value = Allanunciantes.value
            if (buscar.value != '') {
                filtrado.value = true
                anunciantes_aux.value.filter((anunciante)=>{
                    let dato =  anunciante.nombre.toLowerCase().match(buscar.value.toLowerCase()) || anunciante.telefono.match(buscar.value)
                        || anunciante.domicilio.toLowerCase().match(buscar.value.toLowerCase()) || anunciante.ciudad.toLowerCase().match(buscar.value.toLowerCase())
                        || anunciante.correo.toLowerCase().match(buscar.value.toLowerCase())
                        // ||  anunciante.correo_alt.toLowerCase().match(buscar.value.toLowerCase())
                        || anunciante.comentario.toLowerCase().match(buscar.value.toLowerCase()) ;

                    if (dato != null) {
                        arreglo_aux.push(anunciante)
                    }

                })
                anunciantes_aux.value = arreglo_aux
            } else {
                filtrado.value = false
                anunciantes_aux.value = Allanunciantes.value
            }
            pageCont.value = Math.ceil(anunciantes_aux.value.length/paginaInfo.value.pageSize)
            totalElement.value = anunciantes_aux.value.length
            paginaInfo.value = {page: 1, pageSize: mostrar_cantidad.value, prev: null, next: 0}
            if (pageCont.value > 1) {
                paginaInfo.value.next = paginaInfo.value.page +1
            }
            showImpresiones(anunciantes_aux.value)
        }

        const inicializar_clics = () => {
            clics.value.n1 = false
            clics.value.n2 = false
            clics.value.n3 = false
            clics.value.n4 = false
            clics.value.n5 = false
            clics.value.n6 = false
            clics.value.n7 = false
        }

        const ordenar = (tipo) => {
            // console.log(anunciantes_aux.value)

            inicializar_clics()
            switch (tipo) {
                case "nombre":
                    clics.value.n1 = true
                    anunciantes_aux.value = ordenar_arreglo(tipo, ordenarNombre.value, anunciantes_aux.value)
                    ordenarNombre.value = !ordenarNombre.value
                    break;

                case "telefono":
                    clics.value.n2 = true
                    anunciantes_aux.value = ordenar_arreglo(tipo, ordenarTelefono.value, anunciantes_aux.value)
                    ordenarTelefono.value = !ordenarTelefono.value
                    break;

                case "domicilio":
                    clics.value.n3 = true
                    anunciantes_aux.value = ordenar_arreglo(tipo, ordenarDomicilio.value, anunciantes_aux.value)
                    ordenarDomicilio.value = !ordenarDomicilio.value
                    break;

                case "ciudad":
                    clics.value.n4 = true
                    anunciantes_aux.value = ordenar_arreglo(tipo, ordenarCiudad.value, anunciantes_aux.value)
                    ordenarCiudad.value = !ordenarCiudad.value
                    break;

                case "correo":
                    clics.value.n5 = true
                    anunciantes_aux.value = ordenar_arreglo(tipo, ordenarCorreo.value, anunciantes_aux.value)
                    ordenarCorreo.value = !ordenarCorreo.value
                    break;

                case "correo_alt":
                    clics.value.n6 = true
                    anunciantes_aux.value = ordenar_arreglo(tipo, ordenarCorreoAlternativo.value, anunciantes_aux.value)
                    ordenarCorreoAlternativo.value = !ordenarCorreoAlternativo.value
                    break;

                case "comentario":
                    clics.value.n7 = true
                    anunciantes_aux.value = ordenar_arreglo(tipo, ordenarComentario.value, anunciantes_aux.value)
                    ordenarComentario.value = !ordenarComentario.value
                    break;

                default:
                    break;
            }

            showImpresiones(anunciantes_aux.value)
        }

        const traerAnunciantes = () => {
            const client = new GraphQLClient(endpoint) // creamos la consulta para usarlo luego

            watchEffect(() => {

                client.rawRequest(/* GraphQL */ `
                query($page: Int,$first:Int!){
                    anunciantes(first: $first, page: $page){
                        paginatorInfo{
                            count,
                            currentPage,
                            lastPage,
                            perPage
                            total
                        }
                        data{
                            id
                            nombre
                            telefono
                            email
                            email_alternativo
                            domicilio
                            municipio{
                                id,
                                nombre
                                }
                            comentario
                        }
                    }
                }`,
                {
                    page: parseInt(route.params.page),
                    first: mostrar_cantidad.value
                },
                {
                    authorization: `Bearer ${ localStorage.getItem('user_token') }`
                })
                .then((data) => {
                    anunciantes.value = []
                    totalElement.value = data.data.anunciantes.paginatorInfo.total

                    data.data.anunciantes.data.forEach(element => {
                        anunciantes.value.push({id:element.id, nombre: element.nombre, correo: element.email, correo_alt: element.email_alternativo,
                        telefono: element.telefono ,comentario: element.comentario+'',domicilio: element.domicilio,ciudad: element.municipio.nombre, activo: false, tildado: false})

                    })


                }).catch(error => {
                // console.log(error.response);

                })
            })
        }

        const modal = (item) => {
            item.activo = !item.activo
        }

        const tildar = (anunciante) =>{
            anunciante.tildado = !anunciante.tildado
        }

        const marcar_todo = () => {
            todo.value = !todo.value
            if(todo.value){

                anunciantes_aux.value.forEach(element => {
                    element.tildado = true
                })

            }else{
                anunciantes_aux.value.forEach(element => {
                    element.tildado = false
                });
            }
            showImpresiones(anunciantes_aux.value)

        }

        const filtro = () => {
            if (!filtrado.value) {
                limpiarFiltro()
            }
            filtrar.value = true
        }


        const limpiarFiltro = () => {
            datos_filtro.value = {nombre: '', telefono: '', domicilio: '', ciudad: '', correo: '', correo_alt: '', comentario: ''}
            tipo_filtro.value = {nombre: 'contiene', telefono:'es', domicilio: 'contiene', ciudad: 'contiene', correo: 'contiene', correo_alt: 'contiene', comentario: 'contiene'}
            buscar.value = ''
        }

        const filtro_avanzado = () => {
            let aux = []
            let anun_aux = Allanunciantes.value
            if (datos_filtro.value.nombre == '' && datos_filtro.value.telefono == '' && datos_filtro.value.domicilio == ''
            && datos_filtro.value.ciudad == '' && datos_filtro.value.correo == '' && datos_filtro.value.comentario == '') {
                filtrado.value = false
            } else {
                filtrado.value = true
            }

            if (buscar.value != '') {
                filtro_buscador()
            }

            anunciantes.value = anun_aux

            if (datos_filtro.value.nombre != '') {
                anunciantes.value = filtrar_campo_texto(datos_filtro.value.nombre, tipo_filtro.value.nombre, "nombre", anunciantes.value, aux, "anunciantes")
            }
            aux = []

            if (datos_filtro.value.telefono != '' || tipo_filtro.value.telefono == 'vacio') {
                anunciantes.value = filtrar_campo_numeral(datos_filtro.value.telefono, tipo_filtro.value.telefono, "telefono", anunciantes.value, aux, "anunciantes")
            }
            aux = []

            if (datos_filtro.value.domicilio != '' || tipo_filtro.value.domicilio == 'vacio') {
                anunciantes.value = filtrar_campo_texto(datos_filtro.value.domicilio, tipo_filtro.value.domicilio, "domicilio", anunciantes.value, aux, "anunciantes")
            }
            aux = []

            if (datos_filtro.value.ciudad != '') {
                anunciantes.value = filtrar_campo_texto(datos_filtro.value.ciudad, tipo_filtro.value.ciudad, "ciudad", anunciantes.value, aux, "anunciantes")
            }
            aux = []

            if (datos_filtro.value.correo != '') {
                anunciantes.value = filtrar_campo_texto(datos_filtro.value.correo, tipo_filtro.value.correo, "correo", anunciantes.value, aux, "anunciantes")
            }
            aux = []

            if (datos_filtro.value.comentario != '' || tipo_filtro.value.comentario == 'vacio') {
                anunciantes.value = filtrar_campo_texto(datos_filtro.value.comentario, tipo_filtro.value.comentario, "comentario", anunciantes.value, aux, "anunciantes")
            }
            aux = []

            pageCont.value = Math.ceil(anunciantes.value.length/paginaInfo.value.pageSize)
            totalElement.value = anunciantes.value.length
            paginaInfo.value = {page: 1, pageSize: mostrar_cantidad.value, prev: null, next: 0}

            if (pageCont.value > 1) {
                paginaInfo.value.next = paginaInfo.value.page +1
            }
            anunciantes_aux.value = anunciantes.value

            showImpresiones(anunciantes_aux.value)

            filtrar.value = false
        }

        const enviar_correo = () => {
            // console.log('correo')

            anunciantes_aux.value.forEach(element => {
                if (element.tildado == true) {
                    enviarAnunciantes.value.push(element.id)
                }
            })
            // console.log(enviarAnunciantes.value)
            enviar(enviarAnunciantes.value)
        }

        const enviar = (anunciantes) => {

            const client = new GraphQLClient(endpoint)
            // console.log(anunciantes)
            client.rawRequest(/* GraphQL */ `
            mutation($id: [ID!]){
                envioEmails(id: $id)
            }`,
            {
                id: anunciantes
            },
            {
                authorization: `Bearer ${ localStorage.getItem('user_token') }`
            })
            .then((data) => {
                verificacion_de_envio.value = true
                mensaje_correo.value = data.data.envioEmails

                showImpresiones(anunciantes_aux.value)
            })
            .catch(error => {
                verificacion_de_envio.value = false
                mensaje_correo.value = 'No se logro enviar los correos.'
                // console.log(error.response)
            })
        }

        const eliminar = async (anunciante_id) => {
            let habilitado = await queryMe()
            if (habilitado) {
                eliminando(anunciante_id)
            } else {
                router.push({name: 'Login'})
            }
        }


        const eliminando = (anunciante_id) => {

            const client = new GraphQLClient(endpoint)
            console.log(anunciante_id)
            client.rawRequest(/* GraphQL */ `
            mutation($id: ID!){
                eliminaAnunciante(id: $id){
                    id,
                    nombre
                }
            }`,
            {
                id: anunciante_id
            },
            {
                authorization: `Bearer ${ localStorage.getItem('user_token') }`
            })
            .then((data) => {
                let message = data
                // console.log(message)


                accion_exitosa.value = true
                paso_elim.value = true



                let cont = 0
                anunciantes_aux.value.forEach(element => {
                    if (element.id == data.data.eliminaAnunciante.id) {
                        anunciantes_aux.value.splice(cont,1)
                    }
                    cont = cont +1
                })
                // console.log(anunciantes_aux.value)
                pageCont.value = Math.ceil(anunciantes_aux.value.length/paginaInfo.value.pageSize)
                totalElement.value = anunciantes_aux.value.length
                paginaInfo.value = {page: 1, pageSize: mostrar_cantidad.value, prev: null, next: 0}
                if (pageCont.value > 1) {
                    paginaInfo.value.next = paginaInfo.value.page +1
                }
                showImpresiones(anunciantes_aux.value)


            })
            .catch(error => {
                let mensaje = error.message


                accion_exitosa.value = false
                paso_elim.value = false

                // console.log(error.response)
                // console.info( mensaje.includes( 'No se puede eliminar el anunciante. El anunciante tiene Anuncios relacionados'));
                // console.log(mensaje)
                // console.error(error.message)
                if(mensaje.includes( 'No se puede eliminar el anunciante. El anunciante tiene Anuncios relacionados')){
                    mostrar_aviso.value = !mostrar_aviso.value
                    mostrar_nombre.value = anunciante_id
                }

            })
        }

        document.addEventListener('click', function(e) {
            let clic = e.target.getAttribute("id")

            if (clic != "mas-opciones")  {
                mas_opciones.value = false
                if (clic == 'modal-background') {
                    filtrar.value = false
                }
                // No recibo respuesta
            }
        }, false)


        return{
            anunciantes,
            buscar,
            mostrar_cantidad,
            info,
            todo,
            filtrar,
            isMobile,
            anunciantes_aux,
            datos_filtro,
            tipo_filtro,
            mas_opciones,
            eliminar_masivamente,
            eliminarAnunciantes,
            traerAnunciantes,
            modal,
            tildar,
            marcar_todo,
            filtro,
            limpiarFiltro,
            filtro_avanzado,
            enviar_correo,
            eliminar,
            clics ,
            ordenarNombre ,
            ordenarTelefono ,
            ordenarDomicilio,
            ordenarCiudad ,
            ordenarCorreo,
            ordenarCorreoAlternativo ,
            ordenarComentario ,
            nextPage,
            prevPage,
            inicio,
            final,
            paginaInfo,
            pageCont,
            totalElement,
            ordenar,
            enviar,
            enviarAnunciantes,

            verificarHabilitado,
            verificarHabilitadoParam,
            anunciante_elegido ,
            mostrar_advertencia ,
            opsMasivo ,
            cant_elegida ,
            eliminar_adv,
            confirmar,
            cancelar,
            mostrar_aviso ,
            mostrar_nombre ,
            eliminar_adv_masivo ,
            opsEnvio,
            envio_adv_masivo,
            opsIndividual,
            mensaje_correo,
            mostrar_correo_enviado,
            verificacion_de_envio,
            filtrado,
            Allanunciantes,
            mostrar_validacion,
            preload,

            carga_exitosa,
            comprobar,
            comprobar_carga,
            comprobar_edicion ,
            comprobar_edi,
            accion_exitosa,
            paso_elim,

        }
    }
}
</script>

<style>

.boton-tamaño{
    width: 63%;
}

@media screen and (max-width: 700px) {
    .boton-tamaño{
    width: 66%;
  }
}

.para-cel{
    max-width:150px
}

.seleccionado{
    background-color: #01AC97;
}
.seleccionado:hover{
    background-color: #008575;
}

.deseleccionado:hover{
    background-color: #EDEDED;
}

.fondo-fila{
    background-color: rgb(223, 219, 219);
}
/*
.loader-wrapper {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: -1;
    transition: opacity .3s;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
}

.loader {
    height: 80px;
    width: 80px;

}

.is-active-loading {
    opacity: 1;
    z-index: 1;
}

.is-loading {
    margin-top: 20px;
    color:#008575;
    border-block-color: inherit;
    position: relative;
} */
</style>